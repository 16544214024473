body {
  color: #16232a;
  background-color: #FFFFFF;
  font-family: 'Open Sans', sans-serif;
  font-size: 1rem;
  margin: 0;
  -webkit-font-smoothing: antialiased; }

body {
  background-color: #edf3f8;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }

.fullscreen-container {
  background-color: #edf3f8;
  height: 100%;
  position: absolute;
  width: 100%;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }
  .fullscreen-container-menu {
    position: fixed; }

.dark-mode .fullscreen-container {
  background-color: #16232a; }

.welcome-page {
  background-color: #edf3f8;
  height: 100%;
  display: flex;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }
  .welcome-page strong {
    font-weight: 700; }
  .welcome-page-content {
    align-items: center;
    position: relative;
    color: #16232a;
    display: flex;
    flex-direction: column;
    font-size: 2rem;
    height: 100%;
    justify-content: flex-end;
    width: 100%; }
    .welcome-page-content-inner {
      display: flex;
      height: 100%;
      justify-content: center; }
  .welcome-page-particles {
    position: absolute;
    height: 100%;
    width: 100%;
    filter: blur(2px); }
    .welcome-page-particles-canvas {
      width: 100%;
      height: 100%; }
  .welcome-page-hello {
    font-size: 100%;
    align-self: center;
    color: #16232a;
    z-index: 10; }
    .welcome-page-hello-container {
      display: flex;
      flex-direction: column;
      flex: 3;
      justify-content: center;
      align-self: center; }
  .welcome-page-title {
    font-size: 100%;
    text-align: center; }
    .welcome-page-title-container {
      position: absolute;
      display: flex;
      flex-direction: column;
      padding: 1em;
      bottom: 0; }
  .welcome-page-subtitle {
    text-align: center;
    font-size: 40%; }
  .welcome-page-downicon {
    display: flex;
    justify-content: center;
    margin-top: 1em;
    margin-bottom: 1em; }
    .welcome-page-downicon .material-icons {
      font-size: 1.5em; }

.fade-enter {
  opacity: 0; }
  .fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 0.5s ease-in; }

.fade-appear {
  opacity: 0; }
  .fade-appear.fade-appear-active {
    opacity: 1;
    transition: opacity 2s ease-in; }

@media (min-width: 768px) {
  .welcome-page-hello, .welcome-page-ghost {
    font-size: 150%; } }

.dark-mode .welcome-page {
  background-color: #16232a; }
  .dark-mode .welcome-page-content {
    color: #FFFFFF; }
  .dark-mode .welcome-page-hello {
    color: #FFFFFF; }

.greetings-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.greetings-ghost {
  font-size: 100%;
  align-self: center;
  color: white;
  position: absolute; }

.greetings-title {
  font-size: 80%;
  align-self: center;
  color: #16232a;
  z-index: 10;
  font-weight: bold; }

.greetings-tagline {
  font-size: 50%;
  text-align: center;
  color: #5e7283; }
  .greetings-tagline-box {
    color: #FFFFFF;
    background-color: #5e7283;
    padding: 0 5px; }

.dark-mode .greetings-ghost {
  color: white; }

.dark-mode .greetings-title {
  color: #FFFFFF; }

@media (min-width: 768px) {
  .greetings-title, .greetings-ghost {
    font-size: 150%; }
  .greetings-tagline {
    font-size: 100%; } }

.my-introduce {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #edf3f8;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }
  .my-introduce-hi {
    font-size: 2em;
    color: #16232a; }
    .my-introduce-hi-myname {
      font-family: 'Pacifico', cursive;
      color: #16232a; }
    .my-introduce-hi-fauzan {
      font-size: 3em;
      color: #16232a; }
      .my-introduce-hi-fauzan .bold {
        font-weight: bold; }
    .my-introduce-hi-text {
      font-family: 'Pacifico', cursive;
      color: #16232a; }
      .my-introduce-hi-text.hi-2 {
        font-size: 1.2em;
        font-family: 'Open Sans', sans-serif; }
      .my-introduce-hi-text.hi-3 {
        font-size: 1.07em; }
      .my-introduce-hi-text.hi-4 {
        font-size: 1.5em;
        font-family: 'Open Sans', sans-serif; }
        .my-introduce-hi-text.hi-4 .littlebit {
          font-family: 'Pacifico', cursive; }

@media (max-width: 576px) {
  .my-introduce {
    text-align: center; }
    .my-introduce-hi {
      font-size: 1.5em; }
      .my-introduce-hi-fauzan {
        font-size: 2em; }
      .my-introduce-hi-text.hi-1 {
        font-size: 0.75em; }
      .my-introduce-hi-text.hi-2 {
        font-size: 0.9em;
        font-family: 'Open Sans', sans-serif; }
      .my-introduce-hi-text.hi-3 {
        font-size: 0.8em; }
      .my-introduce-hi-text.hi-4 {
        font-size: 1.12em;
        font-family: 'Open Sans', sans-serif; }
        .my-introduce-hi-text.hi-4 .littlebit {
          font-family: 'Pacifico', cursive; } }

.red-dark {
  color: #B6232a;
  font-style: italic;
  font-weight: bold; }

.blue-dark {
  color: #162398;
  font-style: italic; }

.my-contact {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  z-index: 10; }
  .my-contact-soon {
    font-size: 2em;
    padding: 1em; }
  .my-contact-title {
    text-align: center; }
  .my-contact-message {
    text-align: center;
    color: #5e7283;
    font-size: 70%;
    padding: 1em; }
  .my-contact-footer-social {
    display: flex;
    border: 1px solid #5e7283;
    border-radius: 100px;
    min-width: 500px; }
  .my-contact-footer-media {
    flex: 1;
    padding: 1em 0;
    border-right: 1px solid #5e7283;
    text-align: center;
    transition: background-color 0.5s ease;
    font-weight: bold;
    text-decoration: none;
    color: #5e7283; }
    .my-contact-footer-media:first-child {
      border-bottom-left-radius: 100px;
      border-top-left-radius: 100px; }
    .my-contact-footer-media:last-child {
      border-right: 0;
      border-bottom-right-radius: 100px;
      border-top-right-radius: 100px; }
    .my-contact-footer-media:hover {
      cursor: pointer;
      background-color: #5e7283;
      color: #FFFFFF; }
  .my-contact-footer-copyr {
    padding: 1em;
    text-align: center;
    color: #5e7283;
    font-size: 75%; }

@media (max-width: 576px) {
  .my-contact-title {
    font-size: 70%; }
  .my-contact-message {
    font-size: 45%; }
  .my-contact-footer {
    font-size: 65%; }
    .my-contact-footer-social {
      min-width: 285px; }
    .my-contact-footer-media {
      padding: 0.75em 0;
      font-weight: 400; } }

.dark-mode .my-introduce {
  background-color: #16232a; }

.fz-project {
  align-items: center;
  display: flex;
  flex-direction: column;
  text-align: center; }
  .fz-project-header {
    margin-top: 5em;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .fz-project-header-label {
      font-weight: bold;
      font-size: 1.5em;
      text-transform: uppercase; }
    .fz-project-header-title {
      font-size: 3em;
      font-family: 'Pacifico', cursive; }
    .fz-project-header-tagline {
      padding-bottom: 0.5em;
      border-bottom: 1px solid #b2bec8;
      max-width: 30em; }
    .fz-project-header-action {
      display: flex;
      padding: 0.5em 0;
      align-self: flex-start;
      width: 100%; }
      .fz-project-header-action-button {
        display: flex;
        width: 100%; }
        .fz-project-header-action-button-right {
          display: flex;
          justify-content: flex-end;
          flex: 1; }
        .fz-project-header-action-button-name {
          font-size: 70%;
          font-weight: bold;
          padding: 1em 2em;
          background-color: #16232a;
          color: #FFFFFF;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin: 0 0.5em; }
          .fz-project-header-action-button-name:first-child {
            margin-left: 0; }
          .fz-project-header-action-button-name:last-child {
            margin-right: 0; }
          .fz-project-header-action-button-name .material-icons {
            font-size: 1.2em;
            font-weight: bold; }
          .fz-project-header-action-button-name-right {
            align-self: flex-end; }
          .fz-project-header-action-button-name:hover {
            background-color: #395b6d;
            cursor: pointer; }
  .fz-project-soon {
    padding: 0.5em 1em;
    background-color: #16232a;
    color: #edf3f8;
    font-weight: bold;
    margin: 0.5em 0; }
  .fz-project-body {
    max-width: 30em; }

@media (max-width: 576px) {
  .fz-project-header-label {
    font-size: 1em; }
  .fz-project-header-title {
    font-size: 2em; }
  .fz-project-header-tagline {
    font-size: 0.8em; }
  .fz-project-soon {
    font-size: 0.8em;
    padding: 0.3em 0.5em; } }

.fz-project {
  position: relative; }
  .fz-project-member {
    background-color: white;
    border-bottom-left-radius: 5px;
    border-bottom-left-radius: 5px; }
  .fz-project-user {
    padding: 0.5em 1em;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #edf3f8; }
    .fz-project-user:last-child {
      border-bottom: 0; }
    .fz-project-user-picture {
      display: flex; }
      .fz-project-user-picture .fz-picture {
        border-radius: 100px;
        height: 40px; }
    .fz-project-user-name {
      padding: 0.5em; }

.fz-project-stat {
  display: flex;
  width: 100%;
  min-width: 30em;
  background-color: #F5F5F5;
  border-radius: 5px; }
  .fz-project-stat-item {
    padding: 5px 0;
    flex: 1;
    text-align: center;
    transition: background-color 0.5s ease;
    background-color: #f2f2f2;
    border-right: 1px solid #edf3f8;
    color: #16232a;
    text-decoration: none; }
    .fz-project-stat-item:last-child {
      border-right: 0; }
    .fz-project-stat-item:hover {
      color: #395b6d;
      background-color: #e8e8e8; }
    .fz-project-stat-item-value {
      font-weight: bold;
      font-size: 120%; }
    .fz-project-stat-item-label {
      font-size: 80%; }
    .fz-project-stat-item.active {
      background-color: #FFFFFF;
      color: #16232a;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }

@media (max-width: 576px) {
  .fz-project-body {
    max-width: 24em; }
  .fz-project-stat {
    min-width: 24em; } }

.fz-milestones-item {
  display: flex;
  background-color: #FFFFFF;
  border-bottom: 1px solid #edf3f8;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
  justify-content: center;
  align-items: flex-end;
  color: #16232a;
  transition: background-color 0.5s ease; }
  .fz-milestones-item:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-bottom: 0; }
  .fz-milestones-item:hover {
    background-color: #16232a;
    cursor: pointer;
    color: #FFFFFF; }
  .fz-milestones-item-name {
    font-family: 'Pacifico', cursive;
    font-size: 120%;
    flex: 1;
    text-align: left;
    padding: 0.5em 1em; }
    .fz-milestones-item-name.done {
      text-decoration: line-through;
      color: #c8dbea; }
  .fz-milestones-item-comment {
    font-size: 75%;
    text-align: right;
    padding: 1em;
    color: #5e7283; }

.fz-modal {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center; }
  .fz-modal-inner {
    background-color: white;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
    max-width: 24em;
    padding: 2em 0;
    position: relative; }
  .fz-modal-message {
    padding: 0.5em;
    margin-bottom: 1em; }
  .fz-modal-form {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; }
  .fz-modal-input {
    padding: 1em;
    font-size: 1em;
    border: 0;
    border-radius: 5px;
    background-color: #f0f0f0; }
    .fz-modal-input-container {
      margin-bottom: 2em; }
  .fz-modal-close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0.5em; }
    .fz-modal-close:hover {
      color: #eb606b;
      cursor: pointer; }
  .fz-modal-button {
    padding: 1em;
    font-size: 1em;
    border: 0;
    background-color: #16232a;
    color: white;
    border-radius: 5px;
    flex: 1; }
    .fz-modal-button:hover {
      background-color: #395b6d;
      cursor: pointer; }
    .fz-modal-button-container {
      display: flex;
      width: 100%; }

.fz-post {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-family: 'K2D', sans-serif; }
  .fz-post__container {
    margin: 100px 20px 50px 20px; }
  .fz-post__slider {
    max-width: 700px; }
  .fz-post__card {
    display: flex;
    margin: 20px 0; }
    .fz-post__card__label {
      min-width: 80px; }
    .fz-post__card__date-container {
      text-align: center;
      align-self: center;
      margin-top: 20px; }
      .fz-post__card__date-container p {
        margin: 0; }
    .fz-post__card__month {
      font-size: 0.75rem;
      font-weight: 700;
      text-transform: uppercase;
      letter-spacing: 2px; }
    .fz-post__card__date {
      font-weight: 300;
      font-size: 1.5rem; }
    .fz-post__card__year {
      font-size: 0.75rem; }
    .fz-post__card__content {
      -webkit-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
      -moz-box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
      box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
      background-color: #ffffff;
      padding: 10px 20px;
      border-radius: 3px;
      width: 100%;
      -webkit-transition: background-color 1s ease;
      transition: background-color 1s ease; }
  .fz-post__actions {
    display: flex;
    justify-content: flex-start;
    padding: 5px 0; }
  .fz-post__button {
    color: #999;
    padding: 5px 10px;
    font-size: 12px;
    font-weight: 700;
    display: flex;
    background-color: transparent;
    border: 0; }
    .fz-post__button_action .material-icons {
      font-size: 20px; }
    .fz-post__button_more {
      margin-left: auto; }
  .fz-post__date-below-title {
    margin: 0;
    color: #333333;
    font-size: 0.75rem; }
  .fz-post__title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 700;
    letter-spacing: 2px;
    margin: 10px 0;
    color: #16232a; }
  .fz-post__content {
    font-size: 1rem;
    font-weight: 300;
    color: #16232a;
    max-height: 180px;
    overflow: hidden; }
    .fz-post__content p::first-child {
      font-weight: 700; }

@media screen and (max-width: 576px) {
  .fz-post__card__label {
    display: none; } }

@media screen and (min-width: 576px) {
  .fz-post__date-below-title {
    display: none; } }

.dark-mode .fz-post__card__date-container {
  color: #FFFFFF; }

.dark-mode .fz-post__card__content {
  background-color: #000000; }

.dark-mode .fz-post__title {
  color: #FFFFFF; }

.dark-mode .fz-post__content {
  color: #FFFFFF; }

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px; }

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #16232a; }

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px; }

/* Color/shape of close button cross */
.bm-cross {
  background: #FFFFFF; }

/* General sidebar styles */
.bm-menu {
  background: #edf3f8;
  font-size: 1.15em;
  -webkit-transition: background-color 1s;
  transition: background-color 1s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease; }

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #edf3f8; }

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3); }

.menu-item {
  color: #16232a;
  text-decoration: none;
  -webkit-transition: background-color 0.5s linear;
  transition: background-color 0.5s linear; }
  .menu-item:link, .menu-item:visited, .menu-item:active {
    color: #16232a; }

.fz-menu {
  -webkit-transition: background-color 2s ease;
  transition: background-color 2s ease; }
  .fz-menu__logo {
    height: 80px;
    width: 80px;
    margin: 1em;
    margin-bottom: 1.5em; }
  .fz-menu-link {
    color: #16232a;
    text-decoration: none;
    padding: 0.75em;
    font-weight: bold; }
    .fz-menu-link:hover {
      color: #1f313b;
      background-color: #16232a;
      color: #FFFFFF; }
  .fz-menu-navigation {
    display: flex;
    flex-direction: column;
    text-align: center; }
    .fz-menu-navigation-label {
      font-size: 75%;
      padding: 1em; }
  .fz-menu-linksignup {
    color: #FFFFFF;
    text-decoration: none;
    padding: 1em;
    border-radius: 5px; }
    .fz-menu-linksignup:hover {
      background-color: rgba(255, 255, 255, 0.1); }
  .fz-menu-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    background-color: #16232a;
    padding: 2em 0; }
    .fz-menu-profile-picture {
      height: 50px;
      width: 50px;
      border: 3px solid #FFFFFF;
      border-radius: 10em;
      margin: 0.75em; }
    .fz-menu-profile-name {
      color: #FFFFFF;
      text-decoration: none;
      padding: 0.75em;
      border-radius: 5px; }
      .fz-menu-profile-name:hover {
        background-color: rgba(255, 255, 255, 0.1); }
  .fz-menu-dark-mode {
    background-color: #16232a;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 30px);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 4px 15px;
    margin: 10px 15px;
    color: #FFFFFF;
    position: absolute;
    bottom: 0;
    cursor: pointer; }
    .fz-menu-dark-mode:hover {
      opacity: 0.9; }
    .fz-menu-dark-mode__label {
      padding: 10px 5px;
      flex: 1;
      text-align: right; }

.dark-mode .fz-menu-profile {
  background-color: #000000; }

.dark-mode .fz-menu-link:hover {
  background-color: rgba(255, 255, 255, 0.1); }

.dark-mode .fz-menu-dark-mode {
  background-color: #edf3f8;
  color: #16232a; }

.dark-mode .menu-item {
  color: #FFFFFF; }
  .dark-mode .menu-item:link, .dark-mode .menu-item:visited, .dark-mode .menu-item:active {
    color: #FFFFFF; }

.dark-mode .bm-menu {
  background: #16232a;
  font-size: 1.15em; }

.dark-mode .bm-burger-bars {
  background: #FFFFFF; }

.fz-auth-card {
  color: #16232a;
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
  min-width: 320px;
  justify-content: center;
  align-items: center;
  padding: 2em;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }
  .fz-auth-card-title {
    padding: 1em;
    text-align: center;
    font-weight: bold; }
    .fz-auth-card-title.margin {
      margin-bottom: 2em; }
  .fz-auth-card-photo {
    border-radius: 100em; }
  .fz-auth-card-text {
    font-size: 90%;
    text-align: center;
    padding: 1em;
    padding-top: 0; }
    .fz-auth-card-text.margin {
      margin-bottom: 2em; }
  .fz-auth-card-container {
    display: flex;
    justify-content: center;
    align-items: center; }
  .fz-auth-card-inner {
    display: flex;
    flex-direction: column; }
  .fz-auth-card-button {
    text-align: center;
    padding: 1em;
    margin: 1em;
    border-radius: 40px; }
    .fz-auth-card-button.facebook {
      background-color: #3B5998;
      color: #FFFFFF;
      -webkit-transition: background-color 1s ease;
      transition: background-color 1s ease; }
      .fz-auth-card-button.facebook:hover {
        background-color: #4264aa; }
    .fz-auth-card-button:hover {
      cursor: pointer; }

.fz-auth-input {
  border: 0;
  border-radius: 2px;
  font-size: 1em;
  padding: 1em;
  margin: 0.5em;
  color: #FFFFFF;
  background-color: #283f4b; }
  .fz-auth-input::-webkit-input-placeholder {
    color: #FFFFFF; }
  .fz-auth-input:-moz-placeholder {
    color: #FFFFFF; }
  .fz-auth-input::-moz-placeholder {
    color: #FFFFFF; }
  .fz-auth-input:-ms-input-placeholder {
    color: #FFFFFF; }

@media (max-width: 576px) {
  .fz-auth-card {
    padding: 1em; } }

.dark-mode .fz-auth-card {
  color: #FFFFFF;
  background-color: #000000; }
  .dark-mode .fz-auth-card-button.facebook {
    background-color: #FFFFFF;
    color: #4c70ba; }

.fz-editor {
  margin: 100px 20px; }
  .fz-editor__form {
    display: flex;
    flex-direction: column;
    max-width: 700px;
    margin: auto; }

.fz-input {
  margin: 10px 0;
  padding: 15px;
  border: 0;
  border-radius: 5px; }

.fz-button {
  margin: 10px 0;
  padding: 15px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  cursor: pointer; }

.fz-article__background {
  position: fixed;
  z-index: -100;
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  display: flex;
  vertical-align: middle;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }

.fz-article__center-container {
  margin: auto; }

.fz-article__container {
  padding: 60px 25px; }

.fz-article__content {
  font-size: 1.2rem;
  color: #16232a; }
  .fz-article__content p {
    margin-block-start: 1.5em;
    margin-block-end: 1.5em; }

.fz-article__title {
  font-size: 2.2rem;
  color: #16232a; }

.fz-article__close-button {
  position: fixed;
  right: 15px;
  top: 15px;
  cursor: pointer; }
  .fz-article__close-button .material-icons {
    font-size: 36px; }

@media screen and (min-width: 576px) {
  .fz-article__container {
    padding: 80px 36px; }
  .fz-article__close-button {
    top: 36px;
    right: 36px; } }

@media screen and (min-width: 992px) {
  .fz-article__container {
    max-width: 768px;
    margin: auto; } }

.dark-mode .fz-article__background {
  background-color: #16232a; }

.dark-mode .fz-article__content {
  color: #FFFFFF; }

.dark-mode .fz-article__title {
  color: #FFFFFF; }

.fz-loading__background {
  position: fixed;
  z-index: -100;
  background-color: #FFFFFF;
  width: 100%;
  height: 100%;
  display: flex;
  vertical-align: middle;
  -webkit-transition: background-color 1s ease;
  transition: background-color 1s ease; }

.fz-loading__container {
  margin: auto; }

.dark-mode .fz-loading__background {
  background-color: #16232a; }

@media screen and (max-width: 576px) {
  .bm-burger-button {
    left: 20px;
    top: 20px; } }
